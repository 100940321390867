import Trix from 'trix'
import { icon_tag } from '../../utils'
import { registerRichText } from "./utils"

import BaseRichText from "./base_rich_text"

export default class NewsletterRichText extends BaseRichText {
  call() {
    super.call()

    this._extendToolbar()
    this._addEventListeners()
  }

  _extendToolbar() {
    this._attachSalutationAttachmentButton()
  }

  _attachSalutationAttachmentButton() {
    const template = this._salutationButtonTemplate()
    this.fileToolsElement.insertAdjacentHTML("beforeend", template)
  }

  _addEventListeners() {
    this.element.addEventListener("trix-action-invoke", this.handleActionInvocation.bind(this))
  }

  // ===========
  // = Actions =
  // ===========

  handleActionInvocation(e) {
    const { actionName } = e

    switch (actionName) {
    case "x-attach-salutation":
      this._attachSalutation(e)
      break;
    }
  }

  _attachSalutation(event) {
    const { lang } = Trix.config

    const content = `<span class='badge bg-primary rounded-pill'>${lang.salutation}</span>`
    const attachment = new Trix.Attachment({
      content,
      contentType: "vnd.postman.salutation"
    })

    this.editor.insertAttachment(attachment)
  }

  // =============
  // = Templates =
  // =============

  _salutationButtonTemplate() {
    const { lang } = Trix.config

    return `<button type="button" class="btn" title="${lang.attachSalutation}" tabindex="-1" data-trix-action='x-attach-salutation'>${icon_tag("user-tag")}</button>`
  }
}


registerRichText("newsletter", NewsletterRichText)