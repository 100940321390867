class RichTextRegistry {
  constructor() {
    this.registeredRichTexts = {};
  }

  register(name, klass) {
    this.registeredRichTexts[name] = klass
  }

  attach(target) {
    const behaviors = this._parseBehaviors(target.dataset.richtextBehavior)

    behaviors.forEach((behavior) => {
      this._attachBehaviorToTarget(behavior, target)
    })
  }

  _parseBehaviors(behaviorString) {
    if (!behaviorString){
      return []
    }

    return behaviorString.trim().split(/\s/)
  }

  _attachBehaviorToTarget(behavior, target) {
    const behaviorClass = this.registeredRichTexts[behavior]

    if (behaviorClass) {
      this._initializeBehavior(behavior, behaviorClass, target)
    } else {
      console.warn("Missing richtext behavior:", behavior)
    }
  }

  _initializeBehavior(name, klass, target) {
    try {
      new klass(target)
    }
    catch (e) {
      console.error(`Could not attach ${name}`, e)
    }
  }
}

const registryInstance = new RichTextRegistry()
export function start() {
  document.addEventListener("trix-before-initialize", (e) => {
    const { target } = e

    registryInstance.attach(target)
  })
}

export function register(name, klass) {
  registryInstance.register(name, klass)
}