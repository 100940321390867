import Trix, { rangeIsCollapsed } from 'trix'
import BaseRichText from "./base_rich_text"

import { icon_tag } from '../../utils'
import { registerRichText, TrixAttributeObserver } from "./utils"

const DIALOG_NAME = "x-extended-styling"
const REMOVE_ALL_STYLES_BEHAVIOR = "x-remove-all-styles"

const ATTRIBUTES = ["padding", "margin", "display", "borderRadius",  "fontSize", "textAlign"]

function inputNameForAttribute(attribute) {
  return `x-${attribute}`
}

export default class ExtendedStylingRichText extends BaseRichText {
  get buttonElement() { return this.toolbarElement.querySelector(`button[data-trix-action=${DIALOG_NAME}]`) }
  get dialogElement() { return this.toolbarElement.querySelector(`[data-trix-dialog=${DIALOG_NAME}]`) }

  get inputs() { return ATTRIBUTES.map((attribute) => this._findInputElementForAttribute(attribute)) }

  get removeStylesButton() { return this.dialogElement.querySelector(`button[data-behavior=${REMOVE_ALL_STYLES_BEHAVIOR}]`) }

  initialize() {
    this._setupTrixConfig()
  }

  call() {
    super.call()

    this._extendToolbar()
    this._addEventListeners()
    this._installAttributeObserver()
  }

  _setupTrixConfig() {
    const styleAttributeBase = {
      inheritable: true,
      breakOnReturn: true
    }
    Trix.config.textAttributes.textAlign = {
      ...styleAttributeBase,
      styleProperty: "text-align"
    }

    Trix.config.textAttributes.display = {
      ...styleAttributeBase,
      styleProperty: "display"
    }

    Trix.config.textAttributes.padding = {
      ...styleAttributeBase,
      styleProperty: "padding"
    }

    Trix.config.textAttributes.margin = {
      ...styleAttributeBase,
      styleProperty: "margin"
    }

    Trix.config.textAttributes.borderRadius = {
      ...styleAttributeBase,
      styleProperty: "borderRadius"
    }
    Trix.config.textAttributes.fontSize = {
      ...styleAttributeBase,
      styleProperty: "fontSize"
    }
  }

  _extendToolbar() {
    this._attachExtendedStylesButton()
    this._attachExtendedStylesDialog()
  }

  _attachExtendedStylesButton() {
    const template = this._extendedStylesButtonTemplate()

    this.textToolsElement.insertAdjacentHTML("beforeend", template)
  }

  _attachExtendedStylesDialog() {
    const template = this._extendedStylesDialogTemplate()

    this.dialogsElement.insertAdjacentHTML("beforeend", template)
  }

  _installAttributeObserver() {
    this.attributeObserver = new TrixAttributeObserver(this.element, this._didChangeAttributes.bind(this))
    this.attributeObserver.observe(ATTRIBUTES)
  }

  // =============
  // = Templates =
  // =============

  _extendedStylesButtonTemplate() {
    const { lang } = Trix.config

    return `<button type="button" class="btn" title="${lang.extendedStyles}" tabindex="-1" data-trix-action='${DIALOG_NAME}'>${icon_tag("magic-wand-sparkles")}</button>`
  }

  _extendedStylesDialogTemplate() {
    const { lang } = Trix.config

    const inputs = ATTRIBUTES.map((attribute) => {
      const label = lang[attribute] || attribute

      return `
        <div class='col-6 mb-3'>
          <label class='form-label fs-6' for='trix-${attribute}-input'>${label}</label>
          <input type="text" id="trix-${attribute}-input" name="${inputNameForAttribute(attribute)}" class="form-control string-input">
        </div>
      `
    })

    return `
      <div class="trix-dialog trix-dialog--extended-styles" data-trix-dialog="${DIALOG_NAME}">
        <div class='row'>
        ${inputs.join("\n")}
        </div>
        <div class='d-grid'>
          <button type='button' class='btn btn-outline-primary mt-3' data-behavior='${REMOVE_ALL_STYLES_BEHAVIOR}'>${lang.removeAllStyles}</button>
        </div>
      </div>
    `
  }

  // ==================
  // = Event Handling =
  // ==================

  _addEventListeners() {
    this.element.addEventListener("trix-toolbar-dialog-show", this._didShowDialog.bind(this))
    this.element.addEventListener("trix-toolbar-dialog-hide", this._didHideDialog.bind(this))

    ATTRIBUTES.forEach((attribute) => {
      const inputEl = this._findInputElementForAttribute(attribute)

      inputEl.addEventListener("change", () => this._didChangeInput(inputEl, attribute))
    })

    this.removeStylesButton.addEventListener("click", this._didClickRemoveStylesButton.bind(this))
  }

  _didChangeAttributes(attributes) {
    this.currentAttributes = attributes

    this._updateButton()

    if (this._dialogIsActive()) {
      this._updateDialog()
    }
  }

  _didShowDialog(e) {
    const { dialogName } = e

    if (dialogName == DIALOG_NAME) {
      this._updateDialog()
      this._updateButton()
    }
  }

  _didHideDialog(e) {
    const { dialogName } = e

    if (dialogName == DIALOG_NAME) {
      this._resetDialog()
      this._updateButton()
    }
  }

  _didChangeInput(input, attribute) {
    const { value } = input

    this._editAndClose(`Change ${attribute}`, () => this._updateCurrentAttribute(attribute, value))
  }

  _didClickRemoveStylesButton() {
    this._editAndClose("Remove styling", () => this._removeAllStyles())
  }

  // ==================
  // = Editor Updates =
  // ==================

  _editAndClose(actionDescription, callback) {
    this._recordUndoEntry(actionDescription)
    callback.call(this)
    this._hideDialog()
  }


  _updateCurrentAttribute(named, value) {
    if (value) {
      if (this.editor.attributeIsActive(named)) {
        this.editor.deactivateAttribute(named)
      }
      this.editor.activateAttribute(named, value)
    } else {
      this.editor.deactivateAttribute(named)
    }
  }

  _removeAllStyles() {
    ATTRIBUTES.forEach((attribute) => {
    this._updateCurrentAttribute(attribute, "")

    })
  }

  _isValidColor(color) {
    return color
  }

  _recordUndoEntry(description) {
    const selectedRange = this.editor.getSelectedRange()

    if (!rangeIsCollapsed(selectedRange)) {
      this.editor.recordUndoEntry(description, {
        context: selectedRange,
        consolidatable: true
      })
    }
  }

  // ===================
  // = Button Handling =
  // ===================

  _updateButton() {
    const coloringActive = this._shouldActivateButton()

    this.buttonElement.classList.toggle("trix-active", coloringActive)
  }

  _shouldActivateButton() {
    return this._hasActiveStyle() || this._dialogIsActive()
  }

  // ===================
  // = Dialog Handling =
  // ===================

  _updateDialogLater() {
    requestAnimationFrame(() => this._updateDialog())
  }

  _updateDialog() {
    const attributes = this.currentAttributes

    ATTRIBUTES.forEach((attr) => {
      const input = this._findInputElementForAttribute(attr)

      input.value = attributes[attr] || ""
    })

    this._toggleRemoveAllStylesButton(this._selectionHasAnyStyles())
  }

  _resetDialog() {
    this.inputs.forEach((el) => el.value = "")
  }

  _hideDialog() {
    requestAnimationFrame(() => {
      // this.element.editorController.toolbarController.hideDialog()
    })
  }

  _dialogIsActive() {
    return this.dialogElement.hasAttribute("data-trix-active")
  }

  _toggleRemoveAllStylesButton(visible) {
    this.removeStylesButton.classList.toggle("d-none", !visible)
  }

  // ==================
  // = Editor Helpers =
  // ==================

  _findInputElementForAttribute(attribute) {
    return this.dialogElement.querySelector(`input[name='${inputNameForAttribute(attribute)}']`)
  }

  _hasActiveStyle() {
    return ATTRIBUTES.some((attribute) => this.currentAttributes[attribute])
  }

  _selectionHasAnyStyles() {
    const trixDocument = this.editor.getDocument()
    const range = this.editor.getSelectedRange()
    const [start, end] = range

    if (rangeIsCollapsed(range)) {
      return false
    } else {
      for (let i = start; i <= end; i++) {
        const attributesAtPosition = trixDocument.getCommonAttributesAtPosition(i)

        const anyAttributeActive = ATTRIBUTES.some((attribute) => !!attributesAtPosition[attribute])

        if (anyAttributeActive) {
          return true
        }
      }
    }

    return false
  }
}

registerRichText("extended-styling", ExtendedStylingRichText)