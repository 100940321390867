export default class BaseRichText {
  get toolbarElement() { return this.element.toolbarElement }

  get dialogsElement() { return this.toolbarElement.querySelector(".trix-dialogs")}
  get textToolsElement() { return this.toolbarElement.querySelector(".trix-button-group--text-tools") }
  get fileToolsElement() { return this.toolbarElement.querySelector(".trix-button-group--file-tools") }

  get editor() { return this.element.editor }

  constructor(element) {
    this.element = element
    this.element.addEventListener("trix-initialize", () => this.call())

    this.initialize()
  }

  initialize() {
    // Noop
  }

  call() {
    // Noop
  }
}